<template>
  <div class="footer">
    <span>{{ $t('footer.copyright') }}</span>
    <template v-if="!shouldHideBeian">
      <a href="https://beian.miit.gov.cn/" target="_blank">{{ $t('footer.icp') }}</a>
      <img src="@/assets/beian.png" alt="备案图标" />
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017762" rel="noreferrer" target="_blank">{{ $t('footer.police') }}</a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    shouldHideBeian() {
      // if (typeof window !== 'undefined') {
      //   const currentUrl = window.location.href.toLowerCase();
      //   return currentUrl.includes('agent-network-protocol.com/');
      // }
      return false;
    }
  }
};
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f8;
  padding: 10px;
  text-align: center;
  font-size: 14px; /* 统一文字大小 */
  color: #000; /* 统一文字颜色 */
}

.footer a {
  margin: 0 10px;
  text-decoration: none;
  color: #000; /* 统一文字颜色 */
}

.footer img {
  width: 20px;
  height: 20px;
}
</style>
