import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import { getTitle, getICP, getPolice } from './utils/domain';

// Set app title and ICP based on domain
const { zh: zhTitle, en: enTitle } = getTitle();
const icp = getICP();
const police = getPolice();

i18n.global.mergeLocaleMessage('zh', {
  app: {
    title: zhTitle
  },
  footer: {
    icp: icp,
    police: police
  }
});

i18n.global.mergeLocaleMessage('en', {
  app: {
    title: enTitle
  },
  footer: {
    icp: icp,
    police: police
  }
});

const app = createApp(App);
app.use(router);
app.use(i18n);
app.mount('#app');
