/**
 * Check if the current domain is agent-network-protocol.com
 * @returns {boolean}
 */
export const isAgentNetworkProtocol = () => {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href.toLowerCase();
    return currentUrl.includes('agent-network-protocol.com/');
  }
  return false;
};

/**
 * Get the company title based on domain
 * @returns {Object} title in both Chinese and English
 */
export const getTitle = () => {
  if (isAgentNetworkProtocol()) {
    return {
      zh: '杭州比特智元科技',
      en: '杭州比特智元科技'
    };
  }
  return {
    zh: '圆周无限',
    en: '圆周无限'
  };
};

/**
 * Get ICP number based on domain
 * @returns {string}
 */
export const getICP = () => {
  if (isAgentNetworkProtocol()) {
    return '浙ICP备2023026390号-4';
  }
  return '浙ICP备2023026390号-3';
};

/**
 * Get police record number based on domain
 * @returns {string}
 */
export const getPolice = () => {
  if (isAgentNetworkProtocol()) {
    return '';
  }
  return '浙公网安备33011002017762';
}; 