<template>
  <div>
    <template v-if="isMobile">
      <MobileHero />
      <MobileProduct :id="$t('product.id')" :title="$t('product.title')" />
      <MobileAbout :id="$t('about.id')" :title="$t('about.title')" />
      <MobileFooter />
    </template>
    <template v-else>
      <Hero />
      <Product :id="$t('product.id')" :title="$t('product.title')" />
      <About :id="$t('about.id')" :title="$t('about.title')" />
      <Footer />
    </template>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import About from '@/components/About.vue';
import Product from '@/components/Product.vue';
import Footer from '@/components/Footer.vue';
import MobileHero from '@/components/MobileHero.vue';
import MobileAbout from '@/components/MobileAbout.vue';
import MobileProduct from '@/components/MobileProduct.vue';
import MobileFooter from '@/components/MobileFooter.vue';
import { isAgentNetworkProtocol } from '@/utils/domain';

export default {
  components: {
    Hero,
    About,
    Product,
    Footer,
    MobileHero,
    MobileAbout,
    MobileProduct,
    MobileFooter
  },
  data() {
    return {
      isMobile: false
    };
  },
  mounted() {
    // Set title based on domain
    document.title = isAgentNetworkProtocol() ? '杭州比特智元科技' : this.$t('app.title');
    
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = '<%= BASE_URL %>logo.png';
    }
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // 768px 作为移动端的阈值
    }
  }
};
</script>

<style scoped>
/* 可以在这里添加PC和移动端的通用样式 */
</style>
